// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Blogs from './components/Blogs';
import Writers from './components/Writers';
import Stories from './components/Stories';
import Videos from './components/Videos';
import About from './components/About';
import Contact from './components/Contact';
import Creators from './components/Creators';
import Footer from './components/Footer';
import AbdullahSattar from './components/WritersProfiles/AbdullahSattar';
import MahmoodAkhtar from './components/WritersProfiles/MahmoodAkhtar';
import AliouaSelmaHind from './components/WritersProfiles/AliouaSelmaHind';
import Duas from './components/Duas';
//import WriterProfile from './components/WriterProfile'; // Import WriterProfile
import './App.scss';

function App() {
    return (
        <Router>
            <div className="app-content">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="/writers" element={<Writers />} />
                    <Route path="/stories" element={<Stories />} />
                    <Route path="/videos" element={<Videos />} />
                    <Route path="/duas" element={<Duas />} />
                    <Route path="/creators" element={<Creators />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} /> 
                    <Route path="/writers/abdullahsattar" element={<AbdullahSattar />} />
                    <Route path="/writers/mahmoodakhtar" element={<MahmoodAkhtar />} />
                    <Route path="/writers/aliouaselmahind" element={<AliouaSelmaHind />} />
                </Routes>
            </div>
            <div className='footer'> <Footer /> </div>
        </Router>
    );
}

export default App;
