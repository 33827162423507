// src/components/Blogs.js
import React from 'react';
import '../assests/styles/Blogs.scss';
import HomeIcon from './HomeIcon';
import WomensEmpowermentinIslam from '../assests/images/WomensEmpowermentinIslam.jpg';
import WomensEmpowermentinIslamPDF from "../assests/PDFs/WomensEmpowermentInIslam.pdf";
import TheMostSuccessfulEducationist from '../assests/images/TheMostSuccessfulEducationist.jpg';
import TheMostSuccessfulEducationistPDF from '../assests/PDFs/TheMostSuccessfulEducationist.pdf';
import WhatIsTawakkul from "../assests/images/WhatIsTawakkul.jpg";
import WhatIsTawakkulPDF from "../assests/PDFs/WhatIsTawakkul.pdf";
import DownloadButton from './DownloadButton';
import ReadOnlineButton from './ReadOnlineButton';
import { FaInstagram } from 'react-icons/fa';

// Sample blogs data
const blogs = [
    {
        id: 1,
        title: "Women's Empowerment in Islam",
        writer: "Abdullah Sattar",
        image: WomensEmpowermentinIslam,
        fileUrl: WomensEmpowermentinIslamPDF,
        fileName: "Women's Empowerment in Islam - AMONGISLAM",
        linkUrl: "https://medium.com/@amongislam/womens-empowerment-in-islam-1fb60b086204",
        instalink: "https://www.instagram.com/p/C_8JfqnIdX8/?igsh=MW5oNDJ0bjFmZDdsMw==",
    },
    {
        id: 2,
        title: "The Most Successful Educationist",
        writer: "Mahmood Akhtar",
        image: TheMostSuccessfulEducationist,
        fileUrl: TheMostSuccessfulEducationistPDF,
        fileName: "The Most Successful Educationist - AMONGISLAM",
        linkUrl: "https://medium.com/@amongislam/the-most-successful-educationist-5d9ee4a42138",
        instalink: "https://www.instagram.com/p/DAATuBVIJcy/?igsh=MWFpZG1zajNnbDhrdg==",
    },
    {
        id: 3,
        title: "What Is Tawakkul?",
        writer: "Abdullah Sattar",
        image: WhatIsTawakkul,
        fileUrl: WhatIsTawakkulPDF,
        fileName: "What Is Tawakkul - AMONGISLAM",
        linkUrl: "https://medium.com/@amongislam/what-is-tawakkul-3af86ffb7c86",
        instalink: "https://www.instagram.com/p/DBO2tMgyIV0/?igsh=MWdhYm9kcWlsNDBpdw==",
    },
];

const Blogs = () => {
    return (
        <div>
            <HomeIcon />
            <div className='Blogs-Page'>
                <div className='Blogs-Title'>
                    <h1 className="Blogs-Title0">AmongIslam</h1>
                    <h1 className="Blogs-Title1">Blogs</h1>
                </div>
                <div className='Blogs-Container'>
                    {blogs.map((blog) => (
                        <div className='Blogs-Card'>
                            <img src={blog.image} alt={`${blog.title}`} className="Blogs-Image" />
                            <div className="Blogs-Info">
                                <h2>{blog.title}</h2>
                                <h3>Written by: {blog.writer}</h3>
                                <div className='Blog-Buttons'>
                                <DownloadButton
                                    fileUrl={blog.fileUrl}
                                    fileName={blog.fileName}
                                />
                                <ReadOnlineButton
                                    linkUrl={blog.linkUrl}
                                />
                                <a className="blog-insta-icon" href={blog.instalink} target="_blank" rel="noopener noreferrer">
                                    <FaInstagram />
                                </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Blogs;
