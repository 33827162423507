// src/components//WriterProfiles/AbdullahSattar.js
import React from 'react';
import BackButton from '../BackButton';
import HomeIcon from '../HomeIcon';
import abdullahsattarImg from '../../assests/images/abdullahsattar2.png';
import { FaInstagram, FaMedium, FaGlobe } from 'react-icons/fa';
import './AbdullahSattar.scss';

import WomensEmpowermentinIslam from '../../assests/images/WomensEmpowermentinIslam.jpg';
import WomensEmpowermentinIslamPDF from "../../assests/PDFs/WomensEmpowermentInIslam.pdf";
import IbnSinasTwoLambs from '../../assests/images/IbnSinasTwoLambs.jpg';
import IbnSinasTwoLambsPDF from "../../assests/PDFs/IbnSinasTwoLambs.pdf";
import WhatIsTawakkul from "../../assests/images/WhatIsTawakkul.jpg";
import WhatIsTawakkulPDF from "../../assests/PDFs/WhatIsTawakkul.pdf";
import DownloadButton from '.././DownloadButton';
import ReadOnlineButton from '.././ReadOnlineButton';

const writerblogs = [
    {
        id: 1,
        title: "Women's Empowerment in Islam",
        writer: "Abdullah Sattar",
        image: WomensEmpowermentinIslam,
        fileUrl: WomensEmpowermentinIslamPDF,
        fileName: "Women's Empowerment in Islam - AMONGISLAM",
        linkUrl: "https://medium.com/@amongislam/womens-empowerment-in-islam-1fb60b086204",
        instalink: "https://www.instagram.com/p/C_8JfqnIdX8/?igsh=MW5oNDJ0bjFmZDdsMw==",
    },
    {
        id: 2,
        title: "Ibn Sina's Two Lambs",
        writer: "Abdullah Sattar",
        image: IbnSinasTwoLambs,
        fileUrl: IbnSinasTwoLambsPDF,
        fileName: "Ibn Sina's Two Lambs - AMONGISLAM",
        linkUrl: "https://medium.com/@amongislam/ibn-sinas-two-lambs-43a76648438b",
        instalink: "https://www.instagram.com/p/C__DYD-IIuJ/?igsh=YzNreWZqcTg5ZWdq",
    },
    {
        id: 3,
        title: "What Is Tawakkul?",
        writer: "Abdullah Sattar",
        image: WhatIsTawakkul,
        fileUrl: WhatIsTawakkulPDF,
        fileName: "What Is Tawakkul - AMONGISLAM",
        linkUrl: "https://medium.com/@amongislam/what-is-tawakkul-3af86ffb7c86",
        instalink: "https://www.instagram.com/p/DBO2tMgyIV0/?igsh=MWdhYm9kcWlsNDBpdw==",
    },
];

const AbdullahSattar = () => {
    return (
        <div>
            <HomeIcon />
            <div className="abdullahsattar-writer-profile">
                <img src={abdullahsattarImg} alt="Abdullah Sattar" className="abdullahsattar-writer-profile-image" />
                <h1>Abdullah Sattar</h1>
                <div className='abdullahsattar-Writer-Intro'>
                    <h2>Founder & President of AmongIslam</h2>
                    <h2>Author | Sofrware Engineer</h2>
                </div>
                <div className="abdullahsattar-Writer-social-media-icons">
                    <a href="https://www.instagram.com/abdullahsattaar/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                    </a>
                    <a href="https://medium.com/@abdullahsattaar" target="_blank" rel="noopener noreferrer">
                        <FaMedium />
                    </a>
                    <a href="https://www.abdullahsattar.com" target="_blank" rel="noopener noreferrer">
                        <FaGlobe />
                    </a>
                </div>
                <text className='abdullahsattar-Publish'>Blogs Published: 2</text>
                <text className='abdullahsattar-Publish'>Stories Written: 1</text>
                <text className='abdullahsattar-Writer-About'>
                    Born in Lahore and raised in Faisalabad, Pakistan, Abdullah has earned the distinction of being called an author. By profession, he is a software engineer, but his true passion lies in the pursuit of knowledge—a journey that seems to have no end for him. Driven by a deep desire to understand and practice Islam more profoundly, Abdullah founded the organization AmongIslam in 2023, where he currently serves as its inaugural President.<br /><br />
                    Through AmongIslam, Abdullah envisions uniting Muslims who are seekers of Islamic wisdom and knowledge into a single community. His ultimate goal is to spread the teachings of Islam and foster peace across the world, embodying the core principles of his faith.
                </text>

                <div className='abdullahsattar-Blogs-Container'>
                    {writerblogs.map((blog) => (
                        <div className='abdullahsattar-Blogs-Card' key={blog.id}>
                            <img src={blog.image} alt={`${blog.title}`} className="abdullahsattar-Blogs-Image" />
                            <div className="abdullahsattar-Blogs-Info">
                                <h2>{blog.title}</h2>
                                {/* <h3>Written by: {blog.writer}</h3> */}
                                <div className='abdullahsattar-Blog-Buttons'>
                                <DownloadButton
                                    fileUrl={blog.fileUrl}
                                    fileName={blog.fileName}
                                />
                                <ReadOnlineButton
                                    linkUrl={blog.linkUrl}
                                />
                                <a className="abdullahsattar-blog-insta-icon" href={blog.instalink} target="_blank" rel="noopener noreferrer">
                                    <FaInstagram />
                                </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='abdullahsattar-Writer-Back-Button'> <BackButton to="/writers" label="Back to Writers" /> </div>
            </div>
        </div>
    );
};

export default AbdullahSattar;
