// src/components/About.js
import React from 'react';
import '../assests/styles/About.scss';
import HomeIcon from './HomeIcon';
// import ComingSoon from './ComingSoon';

const About = () => {
    return (
        <div>
            <HomeIcon />
            {/* <ComingSoon/> */}
            <div className='About-Page'>
                <div className='About-Title'>
                    <h1 className="About-Title0">AmongIslam</h1>
                    <h1 className="About-Title1">About</h1>
                    <h1 className='About-Title2'>Founded In 2023</h1>
                </div>
                <div className='About-Container'>
                    <div className='About-Card'>
                        <text className='About-Name'>Goal</text>
                        <text className='About-Detail'>AmongIslam aims to enhance the understanding and spread of Islamic wisdom through modern platforms. Our goal is to empower Muslims to excel in every field, fostering growth and betterment in both individuals and the global community.</text>
                    </div>
                    <div className='About-Card'>
                        <text className='About-Name'>Motive</text>
                        <text className='About-Detail'>Our driving force at AmongIslam is the unity of the ummah. We are motivated by the desire to bring Muslims together under the unified banner of Islam, standing strong as one global community.</text>
                    </div>
                    <div className='About-Card'>
                        <text className='About-Name'>Mission</text>
                        <text className='About-Detail'>Our mission at AmongIslam is to deliver authentic, unbiased teachings of Islam to people worldwide. We aim to unite Muslims by sharing the timeless wisdom of the Quran and the Sunnah of Prophet Muhammad (PBUH).</text>
                    </div>
                    <div className='About-Card'>
                        <text className='About-Name'>Vision</text>
                        <text className='About-Detail'>Our vision is to become the world’s leading platform and organization where Muslims can learn, grow, strengthen their faith, and study the true heroes of Islam.</text>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default About;
