// src/components/Creators.js
import React from 'react';
import '../assests/styles/Creators.scss';
import HomeIcon from './HomeIcon';
import mindapexlogo from '../assests/images/mindapexlogo.png';
import { FaInstagram, FaYoutube } from 'react-icons/fa';

// Sample Creators data
const creators = [
    {
        id: 1,
        name: "MindApex",
        image: mindapexlogo,
        link: "mindapex",
        instalink: "https://www.instagram.com/mindapex/",
        youtubelink: "https://www.youtube.com/@themindapex",
    },
];

const Creators = () => {

    return (
        <div>
            <HomeIcon />
            <div className="Creators-Page">
                <div className='Creators-Title'>
                    <h1 className="Creators-Title0">AmongIslam</h1>
                    <h1 className="Creators-Title1">Creators</h1>
                </div>
                <div className="Creators-Container">
                    {creators.map(creator => (
                        <div className="Creators-Card">
                            <img src={creator.image} alt={`${creator.name}'s profile`} className="Creators-Image" />
                            <div className="Creators-Info">
                                {/* <h2>{creator.name}</h2> */}
                                <div className='Creators-Buttons'>
                                    <a href={creator.instalink} target="_blank" rel="noopener noreferrer">
                                        <FaInstagram />
                                    </a>
                                    <a href={creator.youtubelink} target="_blank" rel="noopener noreferrer">
                                        <FaYoutube />
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Creators;
